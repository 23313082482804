// State
export const state = () => ({
    language: [],
    isMobile: false,
    maintenance: {},
    supportCountry: [],
    exchangeRate: [],
    countries: [],
    displayRegisterModal: false,
    isInternational: false,
    exchangeRateLoading: true,
    exchangeRateByPlayerCurrency: {},
})

// Actions
export const actions = {
    // Get exchange rate
    async getExchangeRate({ commit }) {
        commit('setExchangeRateLoading', true)
        try {
            const response = await this.$axios.get('/exchange-rate/fetch-all')
            commit('setExchangeRate', response.data)
        } catch (error) {
        } finally {
            commit('setExchangeRateLoading', false)
        }
    },
    async getExchangeRateByPlayerCurrency({ commit }, params) {
        commit('setExchangeRateLoading', true)
        try {
            const response = await this.$axios.get('/exchange-rate', { params })
            commit('setExchangeRateByPlayerCurrency', response.data)
        } catch (error) {
        } finally {
            commit('setExchangeRateLoading', false)
        }
    },
    // Get support languages
    async getSupportCountry({ commit }) {
        try {
            const response = await this.$axios.get(
                '/website-setting/support-country'
            )
            const supportCountry = response.data
            commit('setSupportCountry', response.data)
            if (supportCountry.length) {
                this.commit('settings/setCurrency', supportCountry[0]?.currency)
            }
        } catch (error) {
            return true
        }
    },
    // Get languages
    async getLanguage({ commit, rootState, dispatch }, currency) {
        try {
            const response = await this.$axios.get(
                `/languages/currency/${rootState.settings.defaultCurrency}`
            )
            const data = response.data
            // Fallback mechanism: If the locale was not successfully set via the default API
            // and there are available language options, set the default language from the data.
            if (!this.$cookie.get('wl_locale') && data?.length > 0) {
                const getDefaultLang = data.find((el) => el.is_default)
                if (getDefaultLang) {
                    dispatch(
                        'base-settings/updateLocaleAndSetCookie',
                        getDefaultLang.language,
                        { root: true }
                    )
                }
            }
            commit('setLanguage', data)
        } catch (error) {}
    },
    // Check maintanance
    async fetchMaintenance({ commit, rootState }) {
        try {
            const response = await this.$axios.get(
                `/website-setting/website-maintenance/${rootState.settings.defaultCurrency}`
            )
            commit('setMaintenance', response.data)
            return response.data
        } catch (error) {}
    },
    // Set visit website
    async fetchVisitWebsite({ state, dispatch }) {
        // Since Lao and international have different logic, it's better to separate the Actions
        if (state.isInternational) {
            await dispatch('fetchVisitForInternational')
            return
        }
        await dispatch('fetchVisitForLao')
    },
    async fetchVisitForLao({ commit }) {
        const _document = document
        try {
            const response = await this.$axios.get('/visit')
            this.$cookie.set('visit', true, {
                path: '/',
                maxAge: 60 * 60,
            })
            if (response.data.banner_uuid) {
                this.$cookie.set('banner_uuid', response?.data?.banner_uuid)
                this.$cookie.set('banner_url', _document.referrer)
            }
            return true
        } catch (error) {
            return false
        }
    },
    async fetchVisitForInternational({ commit }) {
        // Fetch every Website Page Visit
        // Fetch when only Website is not login yet
        // Fetch when the x-banner-id and x-player-type is not existing in the cookie

        const existingXBannerId = this.$cookie.get('x-banner-id')
        const existingXPlayerType = this.$cookie.get('x-player-type')
        if (
            this.$cookie.get('token') ||
            (existingXBannerId && existingXPlayerType)
        )
            return
        try {
            const response = await this.$axios.get('/visit')
            const xBannerId = response.headers['x-banner-id']
            const xPlayerType = response.headers['x-player-type']
            const expiredIn10Days = 10 * 24 * 60 * 60

            if (xBannerId)
                this.$cookie.set('x-banner-id', xBannerId, {
                    path: '/',
                    maxAge: expiredIn10Days,
                })
            if (xPlayerType)
                this.$cookie.set('x-player-type', xPlayerType, {
                    path: '/',
                    maxAge: expiredIn10Days,
                })
            return true
        } catch (error) {
            return false
        }
    },
    whiteLabel({ commit }) {
        const checkIfInternational = true
        commit('whiteLabel', checkIfInternational)
    },
}

// Mutations
export const mutations = {
    setLanguage(state, data) {
        state.language = data
    },
    setIsMobile(state, data) {
        state.isMobile = data
    },
    setMaintenance(state, val) {
        state.maintenance = val
    },
    setSupportCountry(state, val) {
        state.supportCountry = val
    },
    setExchangeRate(state, val) {
        state.exchangeRate = val
    },
    setCountries(state, val) {
        state.countries = val
    },
    setDisplayRegisterModal(state, val) {
        state.displayRegisterModal = val
    },
    whiteLabel(state, val) {
        state.isInternational = val
    },
    setExchangeRateLoading(state, val) {
        state.exchangeRateLoading = val
    },
    setExchangeRateByPlayerCurrency(state, val) {
        state.exchangeRateByPlayerCurrency = val
    },
}

// Getters
export const getters = {
    language: (state) => state.language.filter((lng) => lng.is_active),
    maintenance: (state) => state.maintenance,
    supportCountry: (state) => state.supportCountry,
    exchangeRate: (state) => {
        const displayBothRateAndReveredRate = []
        if (state.exchangeRate?.length > 0) {
            state.exchangeRate.forEach((el) => {
                // push default currency
                if (el.is_show_lnd) {
                    displayBothRateAndReveredRate.push(el)
                }

                // Swap currency and rates with type 2 only.
                if (el.is_show_lnd_reverse) {
                    const {
                        rate,
                        from_currency: fCurrency,
                        reversed_rate: rRate,
                    } = el
                    const swapEl = {
                        ...el,
                        from_currency: el.to_currency,
                        to_currency: fCurrency,
                        rate: rRate,
                        reversed_rate: rate,
                    }
                    displayBothRateAndReveredRate.push(swapEl)
                }
            })
        }
        return displayBothRateAndReveredRate
    },
    exchangeRatesWithCurrency: (state) => (currency) => {
        const displayBothRateAndReveredRate = []
        if (state.exchangeRate?.length > 0) {
            const data = JSON.parse(JSON.stringify(state.exchangeRate))
            const filteredFromCurrency = data.filter(
                (el) => el.from_currency === currency
            )
            filteredFromCurrency.forEach((el) => {
                // push default currency
                if (el.is_show_lnd) {
                    displayBothRateAndReveredRate.push(el)
                }

                // Swap currency and rates with type 2 only.
                if (el.is_show_lnd_reverse) {
                    const {
                        rate,
                        from_currency: fCurrency,
                        reversed_rate: rRate,
                    } = el
                    const swapEl = {
                        ...el,
                        from_currency: el.to_currency,
                        to_currency: fCurrency,
                        rate: rRate,
                        reversed_rate: rate,
                    }
                    displayBothRateAndReveredRate.push(swapEl)
                }
            })
        }
        return displayBothRateAndReveredRate
    },
    swapAllExchangeRate: (state) => {
        const displayBothRateAndReveredRate = []
        if (state.exchangeRate?.length > 0) {
            state.exchangeRate.forEach((el) => {
                displayBothRateAndReveredRate.push(el)
                const {
                    rate,
                    from_currency: fCurrency,
                    reversed_rate: rRate,
                } = el
                const swapEl = {
                    ...el,
                    from_currency: el.to_currency,
                    to_currency: fCurrency,
                    rate: rRate,
                    reversed_rate: rate,
                }
                displayBothRateAndReveredRate.push(swapEl)
            })
        }
        return displayBothRateAndReveredRate
    },
    countries: (state) => state.countries,
}
